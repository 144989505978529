<template>
    <v-container>
        <v-row>

            <v-col cols="12">
                <welcome />
            </v-col>

            <v-col>
                <div v-if="!$auth.loading">

                    <p>You are {{ loggedIn ? '' : 'not' }} logged in.</p>

                    <v-btn v-if="!loggedIn" @click="login" dark color="primary">Log In</v-btn>

                    <v-btn v-if="loggedIn" @click="logout" dark color="primary">Log Out</v-btn>

                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Welcome from '@/components/Welcome.vue'
export default {
	components: { Welcome },
	computed: {
		loggedIn () {
			return this.$auth.isAuthenticated
		}
	},
	methods: {
		login () {
			return this.$auth.loginWithRedirect()
		},
		logout () {
			return this.$auth.logout({
				returnTo: window.location.origin + '/login'
			})
		}
	}
}
</script>

<style>

</style>