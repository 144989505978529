<template>
    <v-container>
        <v-row class="text-center">
            <v-col cols="12">
                <v-img
                    src="https://getbusie.com/static/img/macbook/range-demo-screen.b6e941567783.png"
                    class="my-3"
                    contain
                    height="200"/>
            </v-col>

            <v-col class="mb-4">
                <h1 class="display-2 font-weight-bold mb-3">
                    Welcome to the Busie Admin Dashboard
                </h1>
                <p class="subheading font-weight-regular">
                    This application provides a high-level view of the Busie platform and the individual systems and applciations which support this platform.
                </p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
	name: 'welcome'
}
</script>

<style>

</style>